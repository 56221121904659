<template>
  <div>
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <TicketContactAdd
      ref="contactAdd"
      :is-sidebar-active.sync="isSidebarActive"
      @add-contact-channel="addContactChannel($event)" />
    <TicketContactDetail
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-ticket-contact="initialTicketContact"
      :selected-item.sync="selectedItem"
      @delete-item="openDeleteModal($event)"
      @update-contact-channel="updateContactChannel($event)" />
    <TicketContactFilter
      :search.sync="filter.searchCodeAndName"
      :status-filter.sync="filter.status"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Contact Channels"
        @active-sidebar="activeSidebar()"
        @change="fetchFilter()" />
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
        @row-clicked="rowClick($event)">
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
        <template #cell(code)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
        </template>
        <template #cell(name)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import TicketContactAdd from '@/components/Tickets/Contact/TicketContactAdd.vue'
import TicketContactDetail from '@/components/Tickets/Contact/TicketContactDetail.vue'
import TicketContactFilter from '@/components/Tickets/Contact/TicketContactFilter.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TableTop from '@/components/TableTop.vue'
import ContactProvider from '@/resources/ContactProvider'

const ContactService = new ContactProvider()

export default {
  components: {
    TicketContactAdd,
    TicketContactDetail,
    TicketContactFilter,
    DeleteModal,
    TableTop
  },
  data () {
    return {
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        searchCodeAndName: '',
        status: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'code', label: 'Code', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'remark', label: 'Remark' },
        // { key: 'updatedAt', label: 'Updated At', sortable: true },
        // { key: 'updatedBy', label: 'Updated By' },
        { key: 'status', label: 'Status' }
      ],
      items: [],
      selectedItem: {},
      initialTicketContact: {},
      deleteItem: {}
    }
  },
  created () {
    this.getContact()
  },
  methods: {
    async getContact () {
      try {
        const data = await ContactService.paginate(this.pagination.page, this.pagination.limit, this.filter)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async addContactChannel (val) {
      try {
        await ContactService.createGroup(val)
        await this.getContact()
        this.resetFormAndToggleSideBar(this.$refs.contactAdd)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Ticket Contact has been added'
          }
        })
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Ticket Contact has been Failed'
          }
        })
      }
    },
    async updateContactChannel (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await ContactService.updateGroup(val.id, payload)
        await this.getContact()
        this.setDataInitialAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Ticket Contact has been updated'
          }
        })
      } catch (err) {
        console.log(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Update Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Ticket Contact  has been Failed'
          }
        })
      }
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await ContactService.deleteGroup(val.ID)
        await this.getContact()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Ticket Contact Type has been deleted'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Ticket Contact has been Failed ${error}`
          }
        })
      }
    },
    activeSidebar () {
      this.isSidebarActive = true
    },
    rowClick (val) {
      this.setDataInitialAndSelectItem(val)
      this.isSidebarDetailActive = true
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    setDataInitialAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialTicketContact = { ...val }
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    },
    fetchFilter () {
      this.setPagination(1)
      this.getContact()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getContact()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
</style>
